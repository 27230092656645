@include media-breakpoint-up(lg) {
  .nav-item,
  .dropdown-item {
    > a {
      @include text-underline-alt(true);
    }
    &.show,
    &.active {
      //background-color: transparent;
      > a {
        border-bottom: 1px solid $body-color;
      }
    }
  }
  .dropdown-menu-full .dropdown-item {
    &:hover, &:focus {
      background-color: transparent;
    }
  }
}