#page-footer {
  h2 {
    font-size: rem(18);
  }
  .list-unstyled a {
    @include text-underline-alt();
  }
  .list-angle a {
    @include text-underline-alt(true);
  }
}