.navbar-collapse {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 1px;
  padding: 0;
  background-color: $white;
  border-bottom: 1px solid $border-color;
  z-index: $zindex-sticky;
  .dropdown-menu-full {
    border-radius: 0;
    border: 0;
    padding: 0;
    .navbar-nav {
      padding: 0;
    }
    .dropdown-item {
      padding: 0;
      margin: 0;
    }
  }

  #main-nav,
  #additional-nav {
    padding-top: .5rem;
    padding-bottom: .5rem;

    .nav-link,
    .dropdown-menu-full .dropdown-item > a,
    .dropdown-menu > a {
      display: block;
      padding: $nav-link-padding-y 1rem;
    }

    @include media-breakpoint-down(md) {
      .nav-item {
        margin-bottom: .5rem;
        &.active {
          position: relative;

          > a:before {
            content: '';
            position: absolute;
            bottom: 0;
            top: 0;
            right: auto;
            left: 0;
            width: 3px;
            background-color: $body-color;
          }
        }
      }
      .dropdown-item.active {
        font-weight: $font-weight-semibold;
      }
    }
  }
  #additional-nav {
    border-top: 1px solid $border-color;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-collapse {
    position: static;
    background: transparent;
    border: 0;
    padding: 0;
    align-items: end;

    #main-nav {
      padding: 0;
      .nav-item {
        margin-right: rem(24);
      }
      .nav-link {
        padding: 0;
        text-transform: uppercase;
      }
      .dropup, .dropright, .dropdown, .dropleft {
        position: static;
      }

      .dropdown-menu-full {
        position: absolute;
        left: 0;
        right: 0;
        padding: rem(60) 0 rem(90);
        border-bottom: 1px solid $border-color;
        margin-top: 15px + 1px;
        .navbar-nav {
          padding: 0;
        }
        .dropdown-item {
          padding: .75rem $grid-gutter-width *.5;
          margin: 0;
          flex: 0 0 33.33333333%;
          max-width: 33.33333333%;
          > a {
            display: inline;
            padding: 0;
            white-space: normal;
            font-size: rem(15);
            &:after {
              @include faw-icon($fa-var-angle-right, 16);
              margin-left: rem(6);
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .navbar-collapse {
    #main-nav {
      .nav-item {
        margin-right: rem(48);
      }
    }
  }
}