.btn-round {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  padding: 0;
  font-size: rem(16);
}

.input-group {
  .btn {
    background-color: $white;
    border-color: $input-border-color;
    color: $secondary;
  }
}