.active-filters {
  .badge {
    border: 1px solid $secondary;
    color: $secondary;
    background-color: $white;
    font-size: rem(14);
    text-decoration: none;
    font-weight: $font-weight-base;
    border-radius: $border-radius-sm;
    padding: .5em;
    [aria-hidden="true"] {
      font-size: rem(16);
      line-height: 1;
    }
    &:hover {
      color: $white;
      background-color: $secondary;
    }
  }
}