.error-page {
  @include media-breakpoint-up(sm) {
    height: 100vh;
  }
  display: flex;
  align-items: center;
  h1 {
    color: $secondary;
    font-weight: $font-weight-semibold;
    height: 240px;
    background: url('/img/logos/bg-404.svg') no-repeat center center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding-top: 20px;
    font-size: 5rem;
  }
  h2 {
    font-size: rem(38);
  }
  .other-link {
    font-size: 1.5rem;
  }
  .to-home {
    font-size: 2rem;
  }
}