.pagination {
  border-radius: 0;
  .page-item {
    .page-link {
      border-radius: 0;
    }
    &:not(.active) {
    .page-link {
      text-decoration: underline;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
  }
}