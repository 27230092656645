.bg-overflow-right {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        background-color: inherit;
        left: -1rem;
        width: 1rem;
        top: 0;
        bottom: 0;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }
    
    &::after {
        content: '';
        background-color: inherit;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        right: -1000%;
    }
}

.bg-overflow-left {
    position: relative;
    
    &::before {
        content: '';
        background-color: inherit;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        left: -1000%;
    }

    &::after {
        content: '';
        position: absolute;
        background-color: inherit;
        right: -1rem;
        width: 1rem;
        top: 0;
        bottom: 0;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}