$cta-offset: 60px;

.hero {
  position: relative;
  padding: 0;
  border-radius: 0;
  @include media-breakpoint-up(md) {
    margin-bottom: $cta-offset;
    background: url("/img/hero-background.jpg") no-repeat center center;
    background-size: cover;
  }
  @include media-breakpoint-up(lg) {
    .row {
      min-height: 280px;
    }
  }
  @include media-breakpoint-up(xl) {
    background-size: 100%;
  }
  .hero-box {
    @include media-breakpoint-down(lg) {
      padding-left: 0;
      padding-right: 0;
    }
    @include media-breakpoint-up(md) {
      transform: translateY($cta-offset);
      .btn {
        min-height: $cta-offset;
      }
    }
    .lead {
      font-size: 2rem;
      font-weight: $font-weight-semibold;
      .btn {
        border: 1px solid rgba($white,.25);
        min-height: 0;
        padding: $btn-padding-y .5rem;
      }
    }
  }
}