#euCookie {
  background-color: $gray-900;
  background-color: rgba($gray-900, 0.9);
  color: $white;
  min-height: 5em;
  padding-top: 1.5em;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 600;

  a {
    text-decoration: none;
  }
}