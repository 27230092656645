#key-figures {
  h3 {
    color: $white;
    font-size: 2rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      margin-top: 2rem;
    }
  }
}