ul, ol {
  &:not(.navbar-nav):not(.breadcrumb):not(.pagination):not(.list-inline) {
    li {
      margin-bottom: .5rem;
    }
  }
  ul, ol {
    &:not(.navbar-nav):not(.breadcrumb):not(.pagination):not(.list-inline) {
      margin-top: .5rem;
    }
  }
}

ul {
  &:not(.navbar-nav):not(.breadcrumb):not(.pagination):not(.list-unstyled):not(.list-inline):not(.list-angle):not(.list-square),
  &.list-dash,
  &.list-dash ul {
    padding: 0;
    list-style-type: none;
    li {
      position: relative;
      padding-left: $li-angle-width;
      &:before {
        content:"—";
        width: $li-angle-width;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0;
        line-height: inherit;
      }
    }
  }
  &.list-square,
  &.list-square ul {
    list-style-type: square;
    padding-left: 1.2rem;
    li {
      display: list-item !important;
    }
  }
  &.list-angle,
  &.list-angle ul {
    padding: 0;
    list-style-type: none;
    li {
      position: relative;
      padding-left: $li-angle-width;
      &:before {
        @include faw-icon($fa-var-angle-right, 16);
        width: $li-angle-width;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0;
        line-height: inherit;
      }
    }
  }

}