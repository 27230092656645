p:last-child {
  margin-bottom: 0;
}
.meta {
  color: $brand-blue-light-25;
  font-size: rem(12);
}
body:not(.home-page) {
  #page-content {
    // background-color: rgba(lime,.1);
    h1, .h1 {
      margin-bottom: 1.2em;
    }
    h2, .h2 {
      color: $brand-blue-light-25;
      margin-bottom: 1em;
      &:not(:first-child) {
        margin-top: 1.5em;
      }
      a {
        color: $brand-blue-light-25;
      }
    }
    h3, .h3 {
      margin-top: 1.25em;
      margin-bottom: 1.25em;
    }
    h4, .h4 {
      color: $brand-blue-light-25;
      font-weight: $font-weight-base;
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
}
// Links
#content {
  a {
   &[target="_blank"],
   &[target="_new"] {
       &:not(.btn):not(.no-icon) {
           &:after{
               margin-left:$space-icon-link;
               @include faw-icon(\f35d);
               font-size: 80%;
               vertical-align: 1px;
           }
           @each $doc, $value in $icon-link {
              @include icon-link($doc, $value);
           }
       }
   }
  }
  a.link-arrow {
    &:after {
        @include faw-icon($fa-var-angle-right);
        margin-left: rem(6);
    }
  }
  a.lexique {
    text-decoration: none;
    border-bottom: 2px dotted $secondary;
  }
}