// Assets
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts" !default;
$assets-path: "static" !default;

// Body font-size
$browser-context: 16;

// Icons link
$icon-link: (
	pdf: \f1c1,
	doc: \f1c2,
	docx: \f1c2,
	xls: \f1c3,
	pptx: \f1c4,
	zip: \f1c6
);
$space-icon-link: 5px;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #666E76;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$brand-blue: #1d3264;
$brand-blue-light-25: #566CA0;
$brand-blue-light-50: #BFC8DE;
$brand-orange: #D63E00;
$brand-orange-light-25: #F5956E;
$brand-orange-light-50: #F5F0E7;

$primary: $brand-blue;
$primary-light: $brand-blue-light-25;
$secondary: $brand-orange;
$light: $brand-orange-light-50;
$dark: $gray-600;
$body-color: $primary;

$theme-colors: () !default;
$theme-colors: map-merge(
	(
		"primary":    $primary,
		"primary-light": $primary-light,
		"secondary":  $secondary,
		"light":      $light,
		"dark":       $dark
	),
	$theme-colors
);

$admin-menu-icon-fill: $white;
$admin-menu-icon-size: 16;
$admin-menu-font-color: $white;

$li-angle-width: 1.2rem;

// Spacers
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
	(
		0: 0,
		1: ($spacer * .25), // 4
		2: ($spacer * .5), // 8
		3: $spacer, // 16
		4: ($spacer * 1.5), // 24
		5: ($spacer * 2), // 32
		6: ($spacer * 2.5), // 40
		7: ($spacer * 3), // 48
		8: ($spacer * 3.5), // 56
		9: ($spacer * 4), // 64
		10: ($spacer * 4.5) // 72
	),
	$spacers
);

// Fonts
$font-family-sans-serif: 'Libre Franklin', Roboto, "Helvetica Neue", Arial, sans-serif;
$font-weight-semibold: 600;
$font-size-base:              1rem;

$h1-font-size:                $font-size-base * 2.25; // 36
$h2-font-size:                $font-size-base * 1.75; // 28
$h3-font-size:                $font-size-base * 1.375; // 22
$h4-font-size:                $font-size-base * 1.25; // 20

$headings-font-weight: $font-weight-semibold;

$lead-color: $brand-blue-light-25;
$lead-font-weight:            400;

// Links
$link-decoration:                         underline;
$link-hover-decoration:                   none;

// Border
$border-color: $brand-blue-light-50;
$border-radius:               .5rem !default;
$border-radius-lg:               .5rem !default;

$hr-border-color: $brand-blue-light-50;

// Dropdown
$dropdown-min-width: 5rem;
$dropdown-link-color:               $body-color;
$dropdown-link-hover-color:         darken($body-color, 5%);
$dropdown-link-hover-bg:            lighten($brand-blue-light-50, 10%);
$dropdown-link-active-color:		$body-color;
$dropdown-link-active-bg:           transparent;

// Navbar
$navbar-light-color: $body-color;
$navbar-padding-y: 1rem;
$navbar-brand-padding-y: 0;

$navbar-light-color: $body-color;
$navbar-light-toggler-border-color: transparent;

// Close
$close-font-size:                   $font-size-base * 1.5;
$close-font-weight:                 400;
$close-text-shadow:                 none;

// Forms
$input-color: $body-color;
$input-border-color: $gray-600;
$input-focus-box-shadow: 0,0,0,.2rem rgba($secondary, .25);
$input-placeholder-color: $brand-blue-light-25;

// Checkboxes
$custom-checkbox-indicator-border-radius: .25rem;

// Breadcrumbs
$breadcrumb-font-size:              .75em;

$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           .5rem !default;

$breadcrumb-margin-bottom:          2.5rem;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $brand-blue-light-25;
$breadcrumb-active-color:           $brand-blue-light-25;
$breadcrumb-divider:                quote("/") !default;

$breadcrumb-border-radius:          0;

// Popover
$popover-bg:                        $body-color;
$popover-max-width:                 276px !default;
$popover-body-color:                $white;
$popover-border-color:              $body-color;

// Tooltip
$tooltip-bg: $body-color;

// Pagination
$pagination-padding-y:              .5rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-line-height:            1.25 !default;

$pagination-color:                  $secondary;
$pagination-bg:                     $white !default;
$pagination-border-width:           0;
$pagination-border-color:           $white;

//$pagination-focus-box-shadow:       $input-btn-focus-box-shadow !default;
//$pagination-focus-outline:          0 !default;
//
$pagination-hover-color:            darken($secondary, 5%);
$pagination-hover-bg:               $white;
//$pagination-hover-border-color:     $gray-300 !default;
//
$pagination-active-color:           $secondary;
$pagination-active-bg:              $white;
//
$pagination-border-radius-sm:       0;
$pagination-border-radius-lg:       0;

// Tables
$table-head-bg:               $brand-blue-light-25;
$table-head-color:            $white;
$table-th-font-weight:        $font-weight-semibold;

// Cards
$card-border-radius:                0;

// Badges
$badge-font-weight: normal;