#more-info {
  h2 {
    font-size: 1rem;
    margin-bottom: 1.25rem;
  }
  h3 {
    font-weight: $font-weight-bold;
    font-size: 1rem;
    &:not(:first-child) {
      margin-top: 1.25em;
    }
  }
  p:not(:last-child) {
    margin-bottom: .5rem;
  }
  #related .content-wysiwyg{
    border-left: 5px solid $brand-orange;
    padding-left: rem(15);
    h2 {
      text-transform: uppercase;
    }
  }
}