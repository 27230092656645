.dropdown-toggle {
  &:after {
    @include faw-icon($fa-var-angle-down, 16);
    border: 0;
    vertical-align: middle;
    margin-left: rem(6);
  }
  &[aria-expanded="true"]:after {
    @include faw-icon($fa-var-angle-up, 16);
    margin-top: -2px;
  }
}