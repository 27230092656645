#backdrop-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: $white;
  opacity: .85;
}
body.menu-open {
  @include media-breakpoint-up(lg) {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
  }
  #page-header {
    position: relative;
    z-index: 1000;
  }
}