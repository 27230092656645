@include media-breakpoint-up(lg) {
    .border-lg-left {
        border-left: 1px solid $border-color;
    }

    .border-big-lg-left {
        border-left: 2px solid $border-color;
    }
}

@include media-breakpoint-up(md) {
    .border-md-left {
        border-left: 1px dotted $border-color;
    }

    .border-big-md-left {
        border-left: 2px dotted $border-color;
    }
}

.rounded-md {
    border-radius: rem(8);
}