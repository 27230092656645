@include media-breakpoint-up(lg) {
  #page-header {
    padding-top: $grid-gutter-width * .5;
    padding-bottom: $grid-gutter-width * .5;
    .header-top {
      font-size: rem(14);
      line-height: em(18, 14);

      .nav-item {
        margin-left: rem(30);
      }
      .nav-link {
        padding: 0;
        color: $brand-blue-light-25;
      }
      #dropdown-locales {
        // remove hover effect ?
        //&:hover, &:focus {
        //  border-color: transparent;
        //}
      }
      .dropdown-menu {
        z-index: $zindex-fixed;
        font-size: rem(14);
      }
    }
    .navbar {
      padding-top: .75rem;
      padding-bottom: 1rem;
    }
  }
}