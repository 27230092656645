@mixin text-underline-alt($hover: false){
  text-decoration: none;
  @if $hover {
    border-bottom: 1px solid transparent;
    &:hover, &:focus {
      border-bottom: 1px solid currentColor;
    }
  } @else {
    border-bottom: 1px solid currentColor;
    &:hover, &:focus {
      border-bottom: 1px solid transparent;
    }
  }
}