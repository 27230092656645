/*
* Let's target IE to respect aspect ratios and sizes for img tags containing SVG files
*
* [1] IE9
* [2] IE10+
*/
/* 1 */
img[src$=".svg"] {
    width: 100%\9;
}
/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img[src$=".svg"] {
        width: 100%\9; 
    }
}

// IE10 and IE11   (Method 2)
// ---------------------------------
@media all and (-ms-high-contrast:none){

}
// ---------------------------------

/*----MS Edge Browser CSS Start----*/
@supports (-ms-ime-align:auto) and (display:-ms-grid){
  ul.nav,
  ul.list-unstyled,
  ul.dropdown-menu {
    list-style: none;
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=) !important;
    list-style-type: none;
  }
}
/*----MS Edge Browser CSS End----*/