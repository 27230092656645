.quick-access {
  h3 {
    font-weight: $font-weight-semibold;
    font-size: $h4-font-size;
    margin-bottom: 1em;
  }
  #quick-access-shortcuts {
    ul {
      column-gap:30px;
      @extend .column-lg-2;
    }
  }
}