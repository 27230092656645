.landing-page {
  @include media-breakpoint-up(sm) {
    height: 100vh;
  }
  display: flex;
  align-items: center;
  a {
    font-size: rem(20);
    font-weight: $font-weight-semibold;
    text-decoration: none;
  }
}