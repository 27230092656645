#toc {
  top: rem(42);
  h2 {
    font-size: rem(12);
    margin-top: 0;
    margin-bottom: 1.75rem;
  }
  .nav-link {
    margin-bottom: 1.25rem;
    padding: 0;
    color: $brand-blue-light-25;
    font-size: rem(14);
    text-decoration: none;
    &.active {
      text-decoration: underline;
    }
  }
}