#searchModal {
  .modal-header {
    border: 0;

    .close {
      color: $white;
      opacity: 1;
      background-color: transparent;
      border: 0;
      font-size: $close-font-size;
    }
  }

  .modal-dialog-centered {
    align-items: stretch;
  }

  .modal-content {
    background-color: $secondary;
    border: 0;
  }

  .search-group {
    .form-control {
      color: $white;
      border-color: $white;
      font-size: rem(24);
      border-radius: 0;
      background-color: rgba(0,0,0,0);
      border-width: 0;
      border-bottom-width: 1px;
      padding-left: 0;

      &::placeholder {
        color: $white !important;
      }
      &:-ms-input-placeholder {
        color: $white !important;
      }
      &::-ms-input-placeholder {
        color: $white !important;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 0 rgba($green,.8) inset !important;
        -webkit-text-fill-color: $white !important;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
    .btn {
      color: $white;
      border-color: transparent;
      border-bottom: 1px solid $white;
      font-size: rem(24);
      border-radius: 0;
      background-color: rgba(0,0,0,0);
    }
  }
}

.search-modal {
  .modal-backdrop {
    background-color: $secondary;

    &.show {
      opacity: 1;
    }
  }
}