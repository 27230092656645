a.card {
  text-decoration: none;
  transition: $transition-base;
  .card-header {
    background-color: $brand-blue-light-25;
    font-size: rem(18);
    @include media-breakpoint-up(md) {
      min-height: 145px;
    }
  }
  .card-body {
    background-color: $light;
    border: 1px solid $light;
  }
  &:hover,
  &:focus {
    transform: translate(0,-10px);
    box-shadow: $box-shadow;
    .card-body {
      border-color: $border-color;
    }
  }
}