a.skip-to-content {
  left:-999px;
  position:absolute;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;
}
a.skip-to-content:focus, a.skip-to-content:active {
  color: #fff;
  background-color:#000;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow:auto;
  margin: 10px 35%;
  padding:5px;
  border-radius: 15px;
  border:4px solid yellow;
  text-align:center;
  font-size:1.2em;
  z-index:999;
}
