.table {
  thead th {
    color: $table-head-color;
    background-color: $table-head-bg;
    border: 0;
  }
  tbody th, td {
    border-top: 0;
    border-bottom: 1px solid $table-border-color;
  }
  caption {
    caption-side: top;
  }
}