.filters-box {
  h2 {
    font-size: rem(20);
  }
  h3 {
    font-size: rem(14);
  }
  .search-group {
    @include media-breakpoint-up(lg) {
      max-width: 50%;
    }
  }
  [data-toggle="collapse"] {
    &:before {
      content: "+";
      display: inline-block;
      width: 1.25rem;
      text-decoration: none;
      font-size: 1.5rem;
      line-height: 1;
      vertical-align: middle;
      margin-top: -3px;
    }
    &[aria-expanded="false"] {
      .text-more {
        display: inline-block;
      }
      .text-less {
        display: none;
      }
    }
    &[aria-expanded="true"] {
      .text-more {
        display: none;
      }
      .text-less {
        display: inline-block;
      }
      &:before {
        content: "–";
        margin-top: -8px;
      }
    }
  }
}
.form-group {
  .form-label {
    font-size: rem(14);
  }
}